// extracted by mini-css-extract-plugin
export var addBC = "cardsRareaTemplate-module--addBC--TJWJ2";
export var address = "cardsRareaTemplate-module--address--XUQ86";
export var cardsCont = "cardsRareaTemplate-module--cardsCont--xkAy3";
export var contactButton = "cardsRareaTemplate-module--contactButton--1CZsY";
export var dataCont = "cardsRareaTemplate-module--dataCont--sRDZe";
export var emailBC = "cardsRareaTemplate-module--emailBC--usWlZ";
export var iconBC = "cardsRareaTemplate-module--iconBC--JB2+A";
export var iconsCont = "cardsRareaTemplate-module--iconsCont--QAGJo";
export var infoCont = "cardsRareaTemplate-module--infoCont--mZdS+";
export var infoPersonal = "cardsRareaTemplate-module--infoPersonal--JtLFM";
export var instagramBC = "cardsRareaTemplate-module--instagramBC--FkDLl";
export var interactiveAssets = "cardsRareaTemplate-module--interactiveAssets---xsyu";
export var linkedinBC = "cardsRareaTemplate-module--linkedinBC--rH20-";
export var mapsBC = "cardsRareaTemplate-module--mapsBC--51fDC";
export var nameBC = "cardsRareaTemplate-module--nameBC--MV5g4";
export var profilePic = "cardsRareaTemplate-module--profilePic--o3Nb1";
export var round = "cardsRareaTemplate-module--round--LZ3Ld";
export var textItem = "cardsRareaTemplate-module--textItem--e3psF";
export var webBC = "cardsRareaTemplate-module--webBC--cLI8g";
export var whatsappBC = "cardsRareaTemplate-module--whatsappBC--idMY2";